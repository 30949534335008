import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Home from './Home/Home'
import News from './News/News'
import Access from './Access/Access'
import Admin from "./Admin/Admin";
import OSS from "./LicenseScreen/LicenseScreen";

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// login
//import { withAuthenticator } from '@aws-amplify/ui-react';
// import { Authenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';

// //API
// import { Auth, API } from 'aws-amplify';
// const showSecret = async function() {
//   const apiName = 'spa-test-api';  // index.jsで指定したnameと同じ名前にする
//   const path = 'order'; 
//   const myInit = { 
//     headers: { 
//       Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
//     },
//   };

//   API.get(apiName, path, myInit)
//   .then(response => {
//     console.log(response);
//     alert(response.Body);
//   })
//   .catch(err => {
//     console.log(err);
//     alert(err);
//   });

// };


// Types
export type CartItemType = {
  id: number;
  category: string;
  description: string;
  image: string;
  price: number;
  title: string;
  amount: number;
}

// Auth.currentCredentials().then(console.log)

const App = () => { //関数Component(stateを持たずレンダリング処理<下のReturn>だけを持つコンポーネント)をarrowでやってる。

  return (
    // // <Authenticator>

    //   {/* <AmplifySignUp
    //     slot="sign-up"
    //     usernameAlias="email"
    //     formFields={[
    //       {
    //         type: "email",
    //         required: true,
    //       },
    //       {
    //         type: "password",
    //         required: false,
    //       },
    //     ]}
    //   /> */}
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/news" exact component={News} />
          <Route path="/access" exact component={Access} />
          <Route path="/admin" exact component={Admin} />
          <Route path="/oss" exact component={OSS} />
          {/* <Route path="/Confirm" exact component={Confirm} />
          <Route path="/Order" exact component={Order} />
          <Route path="/News" exact component={News} />
          <Route path="/bootstrapTest" exact component={BootstrapTest} /> */}
          <Redirect to="/" />
        </Switch>

      </BrowserRouter>
    //      {/* <button id='button' onClick={() => showSecret()}>Click Me!</button> */}
    // //  </Authenticator>

  );

};

export default App;
//export default withAuthenticator(App);