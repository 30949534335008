import CartItem from '../CartItem/CartItem';
//import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button'
import axios from 'axios';
import configData from '../Config/dev.json';

// styles
import { Wrapper } from './Cart.styles';
// Types
import { CartItemType } from '../App';
import { OrderItemType } from '../Order/Order'

//API
//import { Auth, API } from 'aws-amplify';


type Props = {
  cartItems: CartItemType[];
  addToCart: (clickedItem: CartItemType) => void;
  removeFromCart: (id: number) => void;
};

const Cart: React.FC<Props> = ({ cartItems, addToCart, removeFromCart }) => {
  const calculateTotal = (items: CartItemType[]) =>
    items.reduce((ack: number, item) => ack + item.amount * item.price, 0)


  // axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
  // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  // Convert Type to add timestamp
  const timestamp = Math.floor(Date.now() / 1000)
  //console.log(timestamp);

  const orderData = cartItems.map(x => {
    const orderItems: OrderItemType = {
      id: x.id,
      title: x.title,
      price: x.price,
      description: x.description,
      category: x.category,
      image: x.image,
      amount: x.amount,
      timestamp: timestamp
    }
    return orderItems;
  })

  //const url: string = "http://localhost:3001/orders"
  const orderUrl: string = configData.orderUrl;
  const OrderButtonClick = () => {
    if (window.confirm('注文を確定します。宜しいですか？')) {
      console.log("Order!")
      //send email
      axios.post(orderUrl, orderData);
      alert('ご注文ありがとうございます。混雑状況により受け渡し時間が前後します。ご了承ください」（従業員用ならMsg不要)')

      // let d = {};
      // for (const result of orderData) {
      //   d = { ...result }
      // }

      //listで来るのは当たり前なのかも。Dynamoに入るとしたらそうなる。
      // ListできたものをLambdaでバラしてDynamo putするのかも。 apiを複数回実行するのはおかしいから。

      // orderSecret();
      // axios({
      //   method: 'get',
      //   url: orderUrl,
      //   headers: {
      //    // 'Access-Control-Allow-Origin': '*'
      //   //   "Access-Control-Allow-Headers": "Content-Type",
      //   // //     'Accept': 'application/vnd.aws-cdf-v2.0+json',
      //   //   'Content-Type': 'application/json'
      //     'Access-Control-Expose-Headers': 'Access-Control-Allow-Origin',
      //     'Access-Control-Allow-Credentials': true,
      //     'Content-Type': 'application/json',
      //     'Access-Control-Allow-Origin': '*',
      //   }
      //   //data: orderData
      // });

      // axios.defaults.withCredentials = true;


      // // Load the AWS SDK for Node.js
      //let AWS = require('aws-sdk');
      // // Set the region これもSAPでconfig読むとかないから不要。
      //AWS.config.update({ region: 'us-west-2' });

      // let aws = require('aws-sdk');
      // let ses = new aws.SES({ region: "us-west-2" });

      // // // Credentials (SPAなのでcognitoでやってるから以下は不要。以下はserver用)
      // // var credentials = new AWS.SharedIniFileCredentials({profile: 'pil'});
      // // AWS.config.credentials = credentials;

      // // // Create sendEmail params 
      // let params = {
      //   Destination: { /* required */
      //     //   CcAddresses: [
      //     //     'ntada10@itameishi.com',
      //     //     /* more items */
      //     //   ],
      //     ToAddresses: [
      //       'ntada10@itameishi.com',
      //       /* more items */
      //     ]
      //   },
      //   Message: { /* required */
      //     Body: { /* required */
      //       Html: {
      //         Charset: "UTF-8",
      //         Data: "HTML_FORMAT_BODY"
      //       },
      //       Text: {
      //         Charset: "UTF-8",
      //         Data: "TEXT_FORMAT_BODY"
      //       }
      //     },
      //     Subject: {
      //       Charset: 'UTF-8',
      //       Data: 'Test email'
      //     }
      //   },
      //   Source: 'ntada10@itameishi.com', /* required */
      //   ReplyToAddresses: [
      //     'ntada10@itameishi.com',
      //     /* more items */
      //   ],
      // };
      // // Create the promise and SES service object
      //let sendPromise = new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();
      //ses.sendEmail(params).promise()

      // // Handle promise's fulfilled/rejected states
      // sendPromise.then(
      //   function (d: any) {
      //     console.log(d.MessageId);
      //   }).catch(
      //     function (err: any) {
      //       console.error(err, err.stack);
      //     });
    }

  };

  // const testData = {
  //   "key2": "test2",
  //   "key3": "test3"
  // }

  // const orderSecret = async function() {
  //   const apiName = 'marusanApi';  // index.jsで指定したnameと同じ名前にする
  //   const path = '/order';  //大元のURLはindex.tsxに設定
  //   const myInit = { 
  //     body:orderData,
  //     headers: { 
  //       Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  //     }
  //   };

  //   API.post(apiName, path, myInit)
  //   .then(response => {
  //     console.log(response);
  //     alert(response.Body);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //     alert(err);
  //   });

  // };

  return (
    <Wrapper>
      <h2>Your Shopping Cart</h2>
      {cartItems.length === 0 ? <p>No items in cart.</p> : null}
      {cartItems.map(item => (
        <CartItem
          key={item.id}
          item={item}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />

      ))}
      <h2>Total: ${calculateTotal(cartItems).toFixed(2)}</h2>
      {cartItems.length === 0 ? <p></p> :
        <div className="buttons">
          <p>上記の内容でよろしければ、注文をクリックしてください</p>
          <Button
            size="small"
            disableElevation
            variant="contained"
            onClick={OrderButtonClick}
          >
            注文を確定する
          </Button>
        </div>}

    </Wrapper>
  )//toFixedは2decimalsってこと
}

export default Cart;