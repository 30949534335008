import configData from '../Config/dev.json';
import axios from "axios";
import { useState, useEffect } from 'react'

// Styles
import { Wrapper } from './News.styles';
// Types
import Grid from '@material-ui/core/Grid';

import NewsItem from '../NewsItem/NewsItem';
//import { NewsTitle } from '../NewsItem/NewsItem.styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { SideBar } from "../SideBar/Sidebar";


export type NewsItemType = {
  id: string;
  timestamp: number;
  title: string;
  body: string;
}

const newsUrl: string = configData.newsUrl;
const apiKey: string = configData.apiKey;


// const getNews = async (): Promise<NewsItemType[]> =>
//   await (await fetch(newsUrl, {
//     mode: 'no-cors',
//     credentials: 'include',
//     headers: {
//       'Access-Control-Allow-Origin':'*'
//     }
//   })).json();

const News = () => {
  const [newsItems, setNewsItems] = useState([] as NewsItemType[]);

  useEffect(() => {
    axios.get(newsUrl, {
      headers: {
        'Content-Type':'application/json',
        'x-api-key': apiKey
      }
    }).then((response) => {
      setNewsItems(response.data);
    });
  }, []);

  if (!newsItems) return null;

  //   const { data, isLoading, error } = useQuery<NewsItemType[]>(
  //     'news',
  //     getNews
  //   );

  //   if (isLoading) return <LinerProgress />;
  //   if (error) return <div>Something went wring...</div>

  return (
    <Wrapper>
      <SideBar />
      <Header /><br></br><br></br>
      <h1> お知らせ</h1>

      {newsItems?.map(item => (
        <Grid item key={item.timestamp} xs={12} sm={4}>
          <NewsItem news={item} />

        </Grid>
      ))}
      <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      <Footer />

    </Wrapper>

  )
};

export default News;