// Styles
import { Customizedbootstrap } from './Footer.styles';
import { Container, Row, Col } from 'react-bootstrap';
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { SocialIcon } from 'react-social-icons';
import Button from '@material-ui/core/Button'

export type FooterType = {
    name: string
    since: string
    address: string
    tel: string
    openHours: string
    sns: string
    copyRight: string
};

// type Props = {
//     footer: FooterType;
// }

const footer: FooterType = {
    "name": "株式会社 丸三",
    "since" : "創業 1982年",
    "address": "兵庫県宝塚市売布2丁目12-4",
    "tel": "0797843761",
    "openHours": "Open：9:00～18:30（Close 毎週水・日曜）",
    "sns": "Instagramで情報配信中",
    "copyRight": "Nikuno Marusan All right reserved."
}

// const redirectToGoogle = () => {
//   window.location.href = "https://google.com";
// };


const Footer = () => {



    return (
        //fluidはwidthが100%
        <Customizedbootstrap>
            <Container fluid >
                <br></br>
                <Row>
                    <Col><h1>{footer.name}</h1></Col>
                </Row>
                <Row>
                    <Col><h6>{footer.since}</h6></Col>
                </Row>
                <Row>
                    <Col><h2>{footer.address}</h2></Col>
                </Row>
                <Row>
                    <Col><h4>TEL : <a href="tel:0797843761">(0797)84-3761</a></h4></Col>
                </Row>
                <Row>
                    <Col><h5>{footer.openHours}</h5></Col>
                </Row>
                <Row>
                    <Col xs={7} md={4} lg={3}><h5>{footer.sns}</h5></Col>
                    <Col>
                        {/* <button onClick={redirectToGoogle}> */}
                        <a href="https://www.instagram.com/nikunomarusan/?hl=ja">
                            <img src="./img/Instagram_Glyph_Gradient_RGB.png" alt="Instagram" />
                            {/* <SocialIcon url="https://www.instagram.com/nikunomarusan/?hl=ja" network="instagram"/> */}
                        </a>
                        {/* </button> */}
                    </Col>
                </Row>
                <Row>
                    <Col><br></br><h6><AiOutlineCopyrightCircle /> {footer.copyRight}</h6></Col>
                </Row>
            </Container>
            {/* <button id='button' onClick={() => redirectToGoogle()}>Click Me!</button> */}
        </Customizedbootstrap>
    )
};

export default Footer;