import { useState } from "react";

import { Container, Content, ClosedSideBar, OpenSideBar, Logo } from "./Sidebar.styles";

import {
	BsArrowLeft,
	BsFillGeoAltFill,
	BsInfoCircleFill,
	BsHouseDoorFill,
} from "react-icons/bs";

import {RiLogoutCircleRLine} from "react-icons/ri";

import logoImg from "../assets/images/age.png";
import tradeMark from "../assets/images/tradeMark.png";
// import userImg from "../assets/images/eu.jpg";

export function SideBar() {
	const [sideBar, setSideBar] = useState(false);

	function handleChangeSideBar() {
		setSideBar((prevState) => !prevState);
	}
	return (
		<Container>
			<Content>
				{!sideBar ? (
					<ClosedSideBar>
						<nav>
							<button onClick={handleChangeSideBar}>
							<Logo><img src={tradeMark} alt="trade mark"/></Logo>
							</button>

							{/* Links principais do app */}
							<ul>
								<a href="/" title="ホーム">
									<BsHouseDoorFill />
								</a>
								<a href="/news" title="お知らせ">
									<BsInfoCircleFill />
								</a>
								<a href="/access" title="アクセス">
									<BsFillGeoAltFill />
								</a>
							</ul>
						</nav>
						<div>
							<ul>
								{/* <a href="/" title="Notificações">
									<IoNotificationsSharp />
								</a>
								<a href="/" title="Configurações">
									<MdSettings />
								</a> */}
								{/* <a href="/" title="OSS">
									<RiLogoutCircleRLine />
								</a> */}
							</ul>

							{/* <span>
								<img src={userImg} alt="Eu" />
							</span> */}
						</div>
					</ClosedSideBar>
				) : (
					<OpenSideBar>
						<section>
							<nav>
								<span>
									<button onClick={handleChangeSideBar}>
										<BsArrowLeft />
									</button>
								</span>
								<div>
									<img src={logoImg} alt="Logo" />
									<h1>肉の丸三</h1>
								</div>

								{/* Icones principais do app */}
								<ul>
									<a href="/" title="Home">
										<BsHouseDoorFill />
										<p>ホーム</p>
									</a>
									<a href="/news" title="Info">
										<BsInfoCircleFill />
										<p>お知らせ</p>
									</a>
									<a href="/access" title="Access">
										<BsFillGeoAltFill />
										<p>アクセス</p>
									</a>
								</ul>
							</nav>
							<div>
								<ul>
									<a href="/oss">
										<RiLogoutCircleRLine />
										<p> Open Source Licenses </p>
									</a>
								</ul>

								{/* <span>
									<img src={userImg} alt="Eu" />
									<p>Tiago Gonçalves de Castro</p>
								</span> */}
							</div>
						</section>
						<aside onClick={handleChangeSideBar} />
					</OpenSideBar>
				)}
			</Content>
		</Container>
	);
}
