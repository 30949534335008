import styled from 'styled-components';

// export const Wrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column;
//   width: 100%
//   border: 1px solid lightblue;
//   border-radius: 20px;

//   img {
//       max-height 55px;
//       width: 50%;
//       object-fit: cover;
//       border-radius: 20px 20px 0 0;
//   }

//   div {
//       font-family: Arial, Helvetica, sans-serif;
//       padding: rem;
//       height: 100%;
//       width: 100%;
//       background: brown; 
//   }

// `;

export const Customizedbootstrap = styled.div`
  background: black;
  font-family:"游教科書体","YuKyokasho"; font-weight: bold;
  color: white;
  img {
    object-fit: cover;
    border-radius: 20px 20px 0 0;
  }
  
`;

// export const Instaicon = styled.div`

// /*  button {
//     background:black;
//     width:100%;
//     text-align: right;
//   }*/
  
// `;