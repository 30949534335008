import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import configData from './Config/dev.json';
import { Amplify } from 'aws-amplify';
const marusanApiUrl: string = configData.marusanApiUrl;

Amplify.configure({
  Auth: {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_G1IgOY3ft",
    userPoolWebClientId: "4p0ov67asfhan9g5769nsrtnn5",
    identityPoolId: "ap-northeast-1:34d750ad-8435-47c7-9cb1-b80786c5f31d"
  },
  API: { //認証成功したら実行するAPI。reactとcognito理解。
    endpoints: [
      {
        name: "marusanApi",
        endpoint: marusanApiUrl, //まだAuthの設定はしていないので注意。
        region: "ap-northeast-1"
      }
    ]
  }
});

const client = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={client}>
    <App />
  </QueryClientProvider>,
  document.getElementById('root')
);
