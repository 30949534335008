// Styles
import { Wrapper } from './Header.styles';

export type HeaderType = {
    image: string
    tag: string
};

const header: HeaderType = {
    "image": "./img/title-logo-marusan.png",
    "tag": "肉の丸三"
  }


  const Header = () => {    
    return (
        <Wrapper>
            <div>
                <img src={header.image} alt={header.tag} />
            </div>
        </Wrapper>
    )
};

export default Header;