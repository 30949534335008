import { Wrapper, OSSBox } from './LicenseScreen.styles'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { SideBar } from "../SideBar/Sidebar";

interface LicenseFile {
  [key: string]: any
}

const LicenseScreen = () => {
  const licenseFile: LicenseFile = require('./licenses.json')
  const licenseKeys = Object.keys(licenseFile)
  return (
    <Wrapper>
      <SideBar />
      <Header /><br></br><br></br>
      <h5>Open Source</h5>
      <OSSBox>
        <div>
          {licenseKeys.map((packageName: string) => {
            const license = licenseFile[packageName].licenses
            const licenseUrl = licenseFile[packageName].licenseUrl
            return (
              <div key={packageName}>
                <div>{packageName}:</div>
                <div>{license}</div>
                <div>{licenseUrl}</div>
              </div>
            )
          })}
        </div>
      </OSSBox><br></br><br></br>
      <Footer />
    </Wrapper>
  )
}


export default LicenseScreen