import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 95%;
  margin-left:10px;

`;

export const NewsTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-decoration: underline;
`;

export const NewsDate = styled.div`
  font-size: 13px;
`;

