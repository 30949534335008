//import { useState, useEffect } from 'react'
import { useState } from 'react'
import configData from '../Config/dev.json';
import { useQuery } from 'react-query'
// Components
import Item from '../Item/Item';
import Cart from '../Cart/Cart';
import Drawer from '@material-ui/core/Drawer';
import LinerProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Badge from '@material-ui/core/Badge';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NewsItem from '../NewsItem/NewsItem'
import NewsForm from '../News/NewsForm'
// Styles
// import { createNamedExports } from 'typescript';
import { Wrapper, StyledButton, NewsBox, Contents } from '../Home/Home.styles';
import { SideBar } from "../SideBar/Sidebar";
import { AiFillCar } from "react-icons/ai";

//login
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

//API
import { Auth, API } from 'aws-amplify';
// const showSecret = async function() {
//   const apiName = 'marusanApi';  // index.jsで指定したnameと同じ名前にする
//   const path = '/order'; 
//   const myInit = { 
//     headers: { 
//       Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
//     },
//   };

//   API.get(apiName, path, myInit)
//   .then(response => {
//     console.log(response);
//     alert(response.Body);
//   })
//   .catch(err => {
//     console.log(err);
//     alert(err);
//   });

// };

async function signOut() {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    console.log('error signing out: ', error);
  }
}


// Types
export type CartItemType = {
  id: number;
  category: string;
  description: string;
  image: string;
  price: number;
  title: string;
  amount: number;
}

export type NewsItemType = {
  id: string;
  timestamp: number;
  title: string;
  body: string;
}

// export type SimpleApiType = {
//   message: string;
// }


//const url: string = "http://localhost:3001/orders"
const orderUrl: string = configData.orderUrl;
const newsUrl: string = configData.newsUrl;
const productUrl: string = configData.productUrl;
const apiKey: string = configData.apiKey;


const getProducts = async (): Promise<CartItemType[]> =>
  await (await fetch(productUrl, {
    headers: {
      'x-api-key': apiKey
    }
  })).json();

const getNews = async (): Promise<NewsItemType[]> =>
  await (await fetch(newsUrl, {
    headers: {
      'x-api-key': apiKey
    }
  })).json();

const Admin = () => { //関数Component(stateを持たずレンダリング処理<下のReturn>だけを持つコンポーネント)をarrowでやってる。
  const [cartOpen, setCartOpen] = useState(false);　 //初期は何も見せない。PageをLoadした時はcartが閉じてるってこと。
  const [cartItems, setCartItems] = useState([] as CartItemType[]); //これも初期の宣言だけ。Cart.tsxの内容を表示

  const { data: data1, isLoading, error } = useQuery<CartItemType[]>('products', getProducts);
  let { data: data2 } = useQuery<NewsItemType[]>('news', getNews);
  // let { data: data3 } = useQuery<SimpleApiType[]>('hello', checkcors);
  // console.log(data3);
  // console.log(data1);

  if (isLoading) return <LinerProgress />;
  if (error) return <div>Something went wring...</div>

  // id順にsort。（低い順）DynamoDB+API GWではできない。Clientでやる。
  data1?.sort((a, b) => a.id - b.id);
  // console.log(data1);

  // 降順（最新から表示）設定。
  data2?.sort((a, b) => b.timestamp - a.timestamp);
  // console.log(data2);

  //ack is abbreviation of accumulate
  const getTotalItems = (items: CartItemType[]) =>
    items.reduce((ack: number, item) => ack + item.amount, 0);

  const handleAddToCart = (clickedItem: CartItemType) => {
    // prevはpreviousってこと。
    setCartItems(prev => {
      // 1. Is the item already added in the cart? //cartで+押された動きじゃなく、homeで同じitemをadd to cartされた動き
      const isItemInCart = prev.find(item => item.id === clickedItem.id)

      if (isItemInCart) {
        return prev.map(item =>
          item.id === clickedItem.id
            ? { ...item, amount: item.amount + 1 }
            : item
        );
      }
      // First time the item is added
      return [...prev, { ...clickedItem, amount: 1 }];
    });
  };

  const handleRemoveFromCart = (id: number) => {
    setCartItems(prev =>
      prev.reduce((ack, item) => {
        if (item.id === id) {
          if (item.amount === 1) return ack;
          return [...ack, { ...item, amount: item.amount - 1 }];
        } else {
          return [...ack, item];
        }
      }, [] as CartItemType[])
    );
  };

  return (
    <Authenticator>
      <Wrapper>
        <SideBar />
        <Header /><br></br><br></br>
        <StyledButton onClick={() => setCartOpen(true)}>
          <Badge badgeContent={getTotalItems(cartItems)} color='error' overlap='rectangular'>
            <AddShoppingCartIcon />
          </Badge>
        </StyledButton>
        <Contents>
          <h5><a href="/access"><AiFillCar /> 無料駐車場あります</a>(4台)</h5>
          <NewsForm />
          <h3><u>最新情報</u></h3>
          <NewsBox>
            {data2?.map(item => (
              <NewsItem news={item} key={item.id} />
            ))}
          </NewsBox>
          <br></br>
          <Drawer anchor='right' open={cartOpen} onClose={() => setCartOpen(false)}>
            <Cart
              cartItems={cartItems}
              addToCart={handleAddToCart}
              removeFromCart={handleRemoveFromCart}
            />
          </Drawer>
          <h3><u>商品一覧</u></h3>
        </Contents>

        <Grid container spacing={2}>
          {data1?.map(item => (
            <Grid item key={item.id} xs={6} sm={6} md={4} lg={3}>
              <Item item={item} key={item.id} handleAddToCart={handleAddToCart} />

            </Grid>
          ))}
        </Grid>
        <Contents>
          <div>
            ※上記以外にも多数品揃えがあります。お気軽にお問い合わせください。<br></br>
            ※ステーキはご希望のグラム・枚数にてオーダカットいたします。<br></br>
            ※しゃぶしゃぶはご注文後スライスさせて頂きます。赤身 100グラム900円、ロース 100グラム1,100円からご用意しております。<br></br>
            ※御進物、全国地方発送も承っております。<br></br>
            ※お肉の表示価格は100グラム単位です。<br></br>
          </div>
        </Contents>
        <Footer />
        {/* <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              required: true,
            },
            {
              type: "password",
              required: false,
            },
          ]}
        /> */}
        {/* <AmplifySignOut /> */}
      </Wrapper>
      {/* <button id='button' onClick={() => showSecret()}>Click Me!</button> */}
      <button id='button' onClick={() => signOut()}>Sign out</button>
    </Authenticator>


  );

};

export default Admin;
//export default withAuthenticator(Home);
