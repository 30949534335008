import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton'

//Defaultはmargin 40だった
export const Wrapper = styled.div`
  margin: 0px;
`;

export const Contents = styled.div`
  margin-right: 5%;
  margin-left: 5%; 
`;

export const StyledButton = styled(IconButton)`
  position: fixed;
  z-index: 100;
  right: 10px;
  padding:10px;
  top: 20px;
`;

export const NewsBox = styled.div`
  background: black;
  font-family:"游教科書体","YuKyokasho"; font-weight: bold;
  color: white;
  margin-left: auto;
  margin-right: auto;
  width: 100%;                /* 横幅を200pxに指定 */
  height: 300px;               /* 横幅を200pxに指定 */
  border: 1px solid #000;      /* わかりやすくボーダーを引く */
  overflow-y: scroll;          /* 縦方向にスクロール可能にする */
  overflow-x: hidden;          /* 横方向にスクロールは不可能にする */
`;

