import styled from 'styled-components';

export const Wrapper = styled.div`

  margin-left:20px;


/*----------------------------
* メニュー開閉ボタン
*----------------------------*/
// .menu-btn{
//   position: fixed;
//   top: 20px;
//   right: 20px;
//   z-index: 2;
//   width: 40px;
//   height: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #333;
//   color: #fff;
// }

/*----------------------------
* メニュー本体
*----------------------------*/
// .menu{
//   position: fixed;
//   top: 0;
//   right: 0;
//   z-index: 1;
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background: #555;
// }
// .menu__item{
//   width: 100%;
//   height: auto;
//   padding: .5em 1em;
//   text-align: center;
//   color: #fff;
//   box-sizing: border-box;
// }

// /*----------------------------
// * アニメーション部分
// *----------------------------*/

// /* アニメーション前のメニューの状態 */
// .menu{
//   transform: translateX(100vw);
//   transition: all .3s linear;
// }
// /* アニメーション後のメニューの状態 */
// .menu.is-active{
//   transform: translateX(0);
// }`;
