import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton'

//Defaultはmargin 40だった
export const Wrapper = styled.div`
  margin: auto;
  height:100%;
`;

export const OSSBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 95%;                /* 横幅を200pxに指定 */
  height: 500px;               /* 横幅を200pxに指定 */
  border: 1px solid #000;      /* わかりやすくボーダーを引く */
  overflow-y: scroll;          /* 縦方向にスクロール可能にする */
`;

