
// // Styles
//import { Container, Row, Col } from 'react-bootstrap';
import { NewsTitle, NewsDate, Wrapper } from './NewsItem.styles';
//import { Customizedbootstrap } from './NewsItem.styles';


export type NewsItemType = {
    id: string;
    timestamp: number;
    title: string;
    body: string;
}

const convertTime = (timestamp: number) => {
    //const t = new Date(timestamp).toLocaleDateString("ja-JP")
    const date = new Date(timestamp * 1000);
    // Year part from the timestamp
    const year = date.getFullYear();
    // Year part from the timestamp
    const month = date.getMonth() + 1;
    // Day part from the timestamp
    const day = date.getDate();
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    // const seconds = "0" + date.getSeconds();
    // const formattedTime = year + '年' + month + '月' + day + '日' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    const formattedTime = year + '年' + month + '月' + day + '日 ' + hours + ':' + minutes.substr(-2);

    return formattedTime;
}

type Props = {
    news: NewsItemType;
}

const NewsItem: React.FC<Props> = ({ news }) => {

    // const getNews = async (): Promise<NewsItemType[]> =>
    //     await (await fetch('http://localhost:3001/news')).json();

    // const { data, isLoading, error } = useQuery<NewsItemType[]>(
    //     'news',
    //     getNews
    // );

    // if (isLoading) return <LinerProgress />;
    // if (error) return <div>Something went wring...</div>

    return (
        // <Wrapper>
        //     <h1>What's new.</h1>
        //     <Grid container spacing={3}>
        //         {data?.map(item => (
        //             <Grid item key={item.timestamp} xs={12} sm={4}>
        //                 <News currentNews={item} />
        //             </Grid>
        //         ))}
        //     </Grid>
        // </Wrapper>
        <Wrapper>
                <NewsTitle>{news.title}</NewsTitle>
                <NewsDate>（{convertTime(news.timestamp)} 更新）</NewsDate>
                <br></br>
                {news.body}<br></br><br></br>
                <hr></hr>
        </Wrapper>
        /* <table>
                   <tr>
                        <th scope='col'>{news.timestamp}</th>
                        <th scope='col'>{news.title}</th>
                    </tr>
                    <tr>
                        <th scope='row'></th>
                        <td>{news.body}</td>
                    </tr>
            </table> */
    )
};

export default NewsItem