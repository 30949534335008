import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton'

export const Wrapper = styled.div`
  margin: 0px;  
`;

export const StyledButton = styled(IconButton)`
  position: fixed;
  z-index: 100;
  right: 20px;
  top: 20px;
`;

export const NewsPage = styled.div`
  background: lightyellow;
  margin-left: auto;
  margin-right: auto;
  width: 90%;                /* 横幅を200pxに指定 */
  height: 100%;               /* 横幅を200pxに指定 */
  border: 1px solid #000;      /* わかりやすくボーダーを引く */
  overflow-y: scroll;          /* 縦方向にスクロール可能にする */
`;

export const NewsReg = styled.div`
  text-align:center;
`