import React, { useState } from "react";
import axios from 'axios';
import configData from '../Config/dev.json';
// styles
import { NewsReg } from './News.styles';

const newsUrl: string = configData.newsUrl;
const apiKey: string = configData.apiKey;

const NewsForm = () => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("")

    /**
     * タイトル値変更処理
     * @param {*} e 
     */
    const changeTitle = (e: any) => {
        setTitle(e.target.value);
    }

    /**
     * Bodyの値変更処理
     * @param {*} e 
     */
    const changeBody = (e: any) => {
        setBody(e.target.value);
    }

    /**
     * 送信ボタンクリック
     * @param {*} e 
     */
    const clickSubmit = (e: any) => {
        if (window.confirm('Newsを登録します。宜しいですか？')) {
            console.log("Register News article");
            const newsData = {
                title,
                body
            }
            // POST処理
            axios.post(newsUrl, newsData, {
                headers: {
                    'Content-Type':'application/json',
                    'x-api-key': apiKey
                }
            });
            alert('登録しました。更新されているかご確認ください。')
        }
    }
    return (
        <NewsReg>
        <div className="NewsForm">
            <div>
                <label>News Title ：
                    <textarea name="title" id="title" onChange={changeTitle} value={title}></textarea><br></br>
                </label>
            </div><br></br>
            <div>
                <label>News Body：
                    <textarea name="body" id="body" rows={8} onChange={changeBody} value={body}></textarea><br></br>
                </label><br></br><br></br>
                <button type="submit" onClick={clickSubmit}>登録</button>
            </div><br></br>
        </div>
        </NewsReg>
    );
}

export default NewsForm;