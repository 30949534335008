// Styles
import { Wrapper, Contents, Map } from './Access.styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { SideBar } from "../SideBar/Sidebar";
import {BsFillGeoAltFill} from "react-icons/bs";
import {AiFillCar,AiFillHome} from "react-icons/ai";
import {FaMapMarkedAlt,FaTrain } from "react-icons/fa";

const Access = () => {
  return (
    <Wrapper>
      <SideBar />
      <Header /><br></br><br></br>
      <Contents>
        <h1><u><BsFillGeoAltFill /> アクセス</u></h1><br></br>
        <h2><AiFillHome /> 住所</h2>
        <h3>　　兵庫県宝塚市売布2丁目12-4<br></br>
          　　TEL : <a href="tel:0797843761">(0797)84-3761</a><br></br>
          　　営業時間：9:00～18:30<br></br>　　　　　　（定休日 毎週水曜、日曜）
        </h3><br></br>
        <h2><AiFillCar /> お車でお越しのお客様</h2>
        <h3>　　旧176号線沿い、中村耳鼻科の裏の三井のリパーク内に、専用駐車場4台（無料）有り。</h3><br></br>
        <h2><FaTrain /> 電車でお越しのお客様</h2>
        <h3>　　阪急売布神社駅より徒歩約2分。
        </h3><br></br>
        <h2><FaMapMarkedAlt/> 周辺地図</h2>
        <h3><a href="https://goo.gl/maps/WcJwyHzbgzNDyHNs8"><BsFillGeoAltFill />地図アプリで確認</a></h3>
        <br></br>
        <Map>
          <a href="./img/map.png"><img src="./img/map.png" alt="Map" /></a>
        </Map>
        <br></br><br></br>
      </Contents>

      <Footer />
    </Wrapper>
  )
};

export default Access;